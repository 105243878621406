.hint-card {
	$this: &;
	$hint-card-border-color: $color-sandstone-50;
	$hint-card-background-color: $color-golden-sand-lighten-12;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: rem(20);
	margin-top: rem(20);
	border: 1px solid $hint-card-border-color;
	background-color: $hint-card-background-color;

	&__image {
		display: block;
		width: 100%;
		height: auto;
		max-width: rem(250);
		margin-left: auto;
		margin-right: auto;
	}

	@include breakpoint($breakpoint-above-mobile) {
		padding: rem(40);
		margin-top: rem(40);
	}
}