.edit-memory {
	&__details {
		display: flex;
		justify-content: space-between;
	}

	&__details-text {
		flex: 1 1 auto;
	}

	&__heading,
	&__date {
		span {
			display: block;
			font-weight: 700;
		}
	}

	&__heading {
		margin-bottom: rem(5);
	}

	&__date {
		margin: 0;
	}

	&__details-image {
		padding-left: rem(20);
		flex: 0 0 rem(100);
		width: rem(100);
	}

	&__image {
		display: block;
		width: rem(80);
		height: auto;
	}

	&__text-inputs {
		width: 100%;
		min-height: rem(100);
		margin-top: rem(20);
		font-family: inherit;
		font-size: rem(16);
	}

	&__controls {
		display: flex;
		justify-content: flex-end;
		margin-top: rem(20);

		.button {
			margin-left: rem(10);
		}
	}

	@include breakpoint($breakpoint-desktop) {
		display: flex;
		flex-wrap: wrap;

		&__details-text {
			width: rem(130);
		}

		&__text-inputs {
			width: auto;
			margin-left: rem(20);
			margin-top: 0;
			flex: 1 1 auto;
		}

		&__controls {
			width: 100%;
		}
	}
}