.modal-content {
	&--centered {
		text-align: center;
	}

	&__heading {
		margin-bottom: rem(20);
	}

	&__logo-links {
		margin-top: rem(30);
	}

	@include breakpoint($breakpoint-desktop) {
		&__logo-links {
			margin-top: rem(55);
		}
	}
}