.anchor-offset {
	$offset-s: $spacing-header-height-s;
	$offset-l: $spacing-header-height-l;

	position: absolute;
	top: -$offset-s;

	@include breakpoint($breakpoint-desktop) {
		top: -$offset-l;
	}
}