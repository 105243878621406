.popup {
	$this: &;

	position: absolute;
	top:50%;
	left:0%;
	width: auto;	
	pointer-events: none;
	opacity: 0;
	transform-origin: 0% 56%;
	line-height: 1;
	/* margin-left: 0; */

	h3,
	p {
		margin-bottom: 0;
		margin-top: 0;
	}

	&__name {
		font-family: 'orpheuspro', 'Times New Roman', Times, serif;
		font-size: 45px;
		white-space: nowrap;
		transform-origin: center left;
		display: inline-block;
	}

	&__years {
		font-size: 21px;
		padding-top: 15px;
		margin-bottom: 0;
	}

	&__calltoaction {
		display: none;
		font-size: 21px;
		padding-top: 15px;
	}

	div {
		margin-block-start: 0;
		margin-block-end: 0;
		white-space: nowrap;
		color: #282828
	}

	&--mobile {
		transform: scale(0.7);

		#{$this}__calltoaction {
			display: block;
		}
	}
}

