//** Atoms | Site logo

@mixin site-logo-size($height) {
	$ratio: 125 / 80;
	$width: $height * $ratio;

	height: rem($height);
	width: rem($width);
}

.site-logo {
	display: block;
	@include site-logo-size(63);

	@include breakpoint($breakpoint-desktop) {
		@include site-logo-size(72);
	}
}