.date-input {
	&:not(:first-child) {
		margin-top: rem(40);
	}

	&__label {
		color: $color-sandstone;
	}

	&__inputs {
		display: flex;
		margin-top: rem(20);

		.form-field {
			&:not(:first-child) {
				margin-top: 0;
			}
		}
	}

	&__day{
		flex: 0 1 rem(50);
		margin-right: rem(15);
	}

	&__month{
		flex: 0 1 rem(80);
		margin-right: rem(15);
	}

	&__year {
		flex: 0 1 rem(80);
	}
}