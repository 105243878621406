.petals-hero {
	$this: &;
	$button-line-color: $color-sandstone;

	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding-top: rem(30);
	width: 100%;
	text-align: center;
	z-index: 2;

	&__heading {
		margin-bottom: rem(30);
	}

	&__heading-top {
		display: block;
		margin-bottom: rem(10);

		font-size: rem(18);
		letter-spacing: letter-spacing(4, 18);
		line-height: (24 / 18);
	}

	&__heading-bottom {
		display: block;

		font-size: rem(52);
		letter-spacing: letter-spacing(3.71, 52);
		line-height: (50 / 52);
	}

	&__text {
		@include margin-x(auto);

		max-width: rem(650);
	}

	&__button {
		position: relative;
		margin-top: rem(60);

		&::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			display: block;
			width: rem(1);
			height: rem(70);
			background-color: $button-line-color;
		}
	}

	.js & {
		pointer-events: none;

		&--displayblock {
			display : block;
		}

		&--anim {
			pointer-events: auto;

			#{$this}__visible {
				opacity: 1;
			}
		}

		&__heading {
			opacity: 0;
		}

		&__visible {
			opacity: 0;
			transition: opacity .4s ease-in-out;
		}
	}

	@include breakpoint($breakpoint-desktop) {
		&__heading {
			margin-bottom: rem(60);
		}

		&__heading-top {
			font-size: rem(32);
			line-height: (32 / 32);
			margin-bottom: rem(6);
		}

		&__heading-bottom {
			font-size: rem(140);
			line-height: (110 / 140);
		}
	}
}
