.site-footer {
	$footer-color: $color-sandstone;

	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 30px;
	pointer-events: none;
	flex-shrink: 0;
	z-index: 4;

	&__left,
	&__right {
		pointer-events: initial;
	}

	&__left {
		margin-left: -15px;
	}

	&__right {
		margin-right: -7.5px;
	}

	&__audio-toggle {
		margin-left: rem(14);
		color: $footer-color;
	}

	#{$tribute-page} &,
	#{$explore-page} &{
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
	}

	@include breakpoint($breakpoint-desktop) {
		padding: rem(44) rem(60);

		&__left {
			margin-left: 0;

			mc-button {
				margin-right: 15px;
			}
		}

		&__right {
			display: flex;
			align-items: center;
			margin-right: 0;
		}
	}
}