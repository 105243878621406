//** Atoms | Body

body {
	height: 100%;

	font-size: rem(16);
	letter-spacing: letter-spacing(.28);
	line-height: (24 / 16);

	@include breakpoint($breakpoint-desktop) {
		font-size: rem(18);
		letter-spacing: letter-spacing(.32, 18);
		line-height: (29 / 18);
	}
}