.image-swiper {
	list-style: none;
	display: flex;

	&__nav {
		display: flex;
		justify-content: space-between;
		width: rem(173);
		margin-left: auto;
		padding-bottom: rem(70);
	}

	&__nav-item {
		@include size(rem(44));
		display: flex;
		align-items: center;
		justify-content: center;

		transition: opacity .3s ease-in-out;

		&--prev {
			transform: rotate(180deg);
		}

		&--disabled {
			pointer-events: none;
			opacity: .4;
		}
	}

	&__item {
		flex: 0 0 auto;
		width: 90%;

		&:not(:last-child) {
			margin-right: 5%;
		}

		&:nth-child(5n+2) {
			width: columns(10);
			padding-top: 30%;
			margin-right: 10%;
		}

		&:nth-child(5n+3) {
			width: columns(11);
			padding-top: 15%;
			margin-right: 12%;
		}

		&:nth-child(5n+4) {
			width: columns(9.5);
			padding-top: 6%;
			margin-right: 17%;
		}

		&:nth-child(5n+5) {
			width: columns(8);
			padding-top: 24%;
			margin-right: 9%;
		}
	}

	@include breakpoint($breakpoint-desktop) {
		&__item {
			width: columns(5);

			&:not(:last-child) {
				margin-right: rem(70);
			}

			&:nth-child(5n+2) {
				width: columns(4.6);
			}

			&:nth-child(5n+3) {
				width: columns(4.8);
			}

			&:nth-child(5n+4) {
				width: columns(4.5);
			}

			&:nth-child(5n+5) {
				width: columns(5.2);
			}
		}
	}

	@include breakpoint($breakpoint-retina) {
		&__item {
			width: columns(4, 6);

			&:not(:last-child) {
				margin-right: 0;
				padding-right: rem(70);
				box-sizing: content-box;
			}

			&:nth-child(5n+2) {
				width: columns(2.9, 6);
			}

			&:nth-child(5n+3) {
				width: columns(2.4, 6);
			}

			&:nth-child(5n+4) {
				width: columns(3.5, 6);
			}

			&:nth-child(5n+5) {
				width: columns(3.2, 6);
			}
		}
	}
}