.copy-link {
	$copy-link-background-color: $color-golden-sand-lighten-12;
	$copy-link-text-border-color: $color-sandstone-50;

	display: flex;
	align-items: center;
	flex-wrap: wrap;

	&__text {
		width: 100%;
		height: auto;
		min-height: rem(51);
		padding: rem(12) rem(20);
		margin: 0;
		background-color: $copy-link-background-color;
		border: 1px solid $copy-link-text-border-color;
		word-wrap: break-word;
		font-family: inherit;
		font-size: 100%;
	}

	&__button {
		flex: 0 0 auto;
		margin-left: auto;
		margin-top: rem(20);
	}

	@include breakpoint($breakpoint-above-mobile) {
		flex-wrap: nowrap;

		&__text {
			width: auto;
			flex: 1 1 auto;
		}

		&__button {
			margin-top: 0;
			margin-left: rem(30);
		}
	}

	@include breakpoint($breakpoint-desktop) {
		&__text {
			padding: rem(10) rem(20);
		}
	}
}