.form-field {
	$this: &;
	$transition-duration: .35s;

	position: relative;
	display: block;
	transition: border-bottom-color $transition-duration;

	&:not(:first-child) {
		margin-top: rem(40);
	}

	&__label {
		cursor: pointer;
		display: block;
		transition: color $transition-duration;

		&-text,
		&-error {
			transition: opacity $transition-duration;
		}

		&-error {
			opacity: 0;
			pointer-events: none;
			color: $color-milano-red;
		}
	}

	&__input--textarea {
		line-height: 1.2;
	}

	&__char-count {
		position: absolute;
		top: 0;
		right: 0;
		font-size: rem(14);
		color: $color-sandstone;
	}

	&--force-margin {
		margin-top: rem(40);
	}

	&--checkbox {
		$checkbox: &;
		$checkbox-size: 20;

		+#{$checkbox} {
			margin-top: rem(30);
		}

		#{$this}__input {
			@include audio-only();
		}

		#{$this}__label {
			display: flex;

			&-error {
				padding-left: rem($checkbox-size + 15);
				display: none;
			}
		}

		#{$this}__checkbox {
			flex: 0 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			width: rem($checkbox-size);
			height: rem($checkbox-size);
			margin: rem(3) rem(15) 0 0;
			border: rem(1) solid currentColor;
			transition: border-color $transition-duration, color $transition-duration;
			color: currentColor;
			font-size: rem((14 / 20 * $checkbox-size));
			text-align: center;
			line-height: rem($checkbox-size);
			cursor: pointer;
		}

		#{$this}__tick {
			stroke: currentColor;
			stroke-dasharray: 16;
			stroke-dashoffset: 16;
			transition: stroke-dashoffset .1s ease-in-out;
		}

		#{$this}__input {
			[data-whatintent="keyboard"] &:focus {
				+#{$this}__label #{$this}__checkbox {
					outline: rem(2) solid currentColor;
				}
			}
		}

		#{$this}__input:checked+#{$this}__label #{$this}__tick {
			stroke-dashoffset: 0;
		}
	}

	&--text {
		$text: &;

		border-bottom: rem(2) solid $color-charcoal-20;
		color: $color-charcoal;
		font-size: rem(16);
		position: relative;

		#{$this}__label,
		#{$this}__input {
			color: inherit;
			display: block;
			font-size: inherit;
			font-family: inherit;
			line-height: 1;
			padding: .5em 0;
			width: 100%;
		}

		#{$this}__label {
			color: $color-sandstone;
			position: absolute;
			transform-origin: 0 0;
			transition: color $transition-duration ease-in-out, transform $transition-duration ease-in-out;
			user-select: none;

			small {
				font-size: rem(10);
			}

			&-error {
				margin-left: rem(5);

				&::before {
					content: "\2012";
					margin-right: rem(10);
				}
			}
		}

		#{$this}__input {
			border: 0;
			background-color: transparent;
		}

		&#{$this}--active {
			#{$this}__label {
				color: currentColor;
				transform: translateY(rem(-17)) scale(0.7);

				&-text {
					opacity: 1;
				}
			}
		}
	}

	&--search {
		border-bottom-width: rem(1);

		#{$this}__label {
			font-size: rem(28);
			color: $color-charcoal;
		}

		#{$this}__input {
			line-height: (28 / 16);
			padding: rem(14) rem(44) rem(14) 0;

			&::placeholder {
				opacity: 0;
			}
		}

		&#{$this}--active {
			#{$this}__label {
				transform: translateY(rem(-15)) scale(0.5);
			}
		}
	}

	&--invalid {
		border-bottom-color: $color-milano-red-20;

		#{$this}__label#{$this}__label {
			color: $color-milano-red;
		}

		#{$this}__label-error {
			opacity: 1;
			pointer-events: auto;
		}

		&#{$this}--checkbox {
			#{$this}__label-error {
				display: block;
			}
		}
	}

	&--image {
		#{$this}__image-label {
			color: $color-sandstone;
		}

		#{$this}__preview {

			img {
				display: block;
				max-width: 200px;
				margin: 0 auto;
			}
		}

		#{$this}__input {
			&:not(:first-child) {
				margin-top: rem(20);
			}
		}
	}

	&--secondary {
		&:not(:first-child) {
			margin-top: rem(30);
		}

		#{$this}__label {
			padding-bottom: 0;
			line-height: 1.2;
			padding-left: .5em;
		}

		#{$this}__input {
			background-color: $color-white-70;
			border: none;
			padding: .5em;

			&--textarea {
				line-height: 1.2;
				min-height: rem(150);
			}
		}

		&#{$this}--text {
			border-bottom: none;

			&#{$this}--active {
				#{$this}__label {
					transform: translateY(rem(-22)) scale(0.7);
				}
			}
		}
	}

	&--hide-label {
		&#{$this}--text {

			#{$this}__label {
				transition: opacity .3s ease-in-out;
			}

			&#{$this}--active {
				#{$this}__label {
					opacity: 0;
					pointer-events: none;
					transform: none;
				}
			}
		}
	}

	@include breakpoint($breakpoint-desktop) {
		&--text {
			font-size: rem(34);

			#{$this}__label {
				small {
					font-size: 50%;
				}
			}

			&#{$this}--active {
				#{$this}__label {
					transform: translateY(rem(-15)) scale(0.5);
				}
			}
		}

		&--search {
			#{$this}__label {
				@include font($font-button);

				position: relative;
				padding: 0;
				transform: none;
				color: $color-sandstone;
			}

			#{$this}__input {
				line-height: 1;
				padding: rem(10) 0 rem(40);
				font-size: rem(110);

				&::placeholder {
					opacity: 1;
					color: $color-charcoal;
				}
			}

			&#{$this}--active {
				#{$this}__label {
					transform: none;
				}
			}
		}

		&--image {
			display: flex;
			flex-wrap: wrap;

			#{$this}__image-label {
				width: 100%;
			}

			#{$this}__preview {
				flex: 1 1 200px;
				padding-right: rem(20);

				img {
					margin: 0;
				}
			}

			#{$this}__input {
				flex: 1 1 60%;

				&:not(:first-child) {
					margin-top: 0;
				}
			}
		}

		&--secondary {
			font-size: rem(18);

			#{$this}__label {
				small {
					font-size: rem(12);
				}
			}

			&#{$this}--text {
				&#{$this}--active {
					#{$this}__label {
						transform: translateY(rem(-25)) scale(0.7);
					}
				}
			}
		}

		&--hide-label {
			&#{$this}--text {
				&#{$this}--active {
					#{$this}__label {
						transform: none;
					}
				}
			}
		}

	}
}