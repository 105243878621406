//** Atoms | Headings

.h1,
.h2,
.h3 {
	@include font($font-orpheuspro);
}

.h1 {
	font-size: rem(48);
	letter-spacing: letter-spacing(3.43, 48);
	line-height: 1;

	@include breakpoint($breakpoint-desktop) {
		font-size: rem(140);
	}
}

.h2 {
	font-size: rem(46);
	letter-spacing: letter-spacing(3.29, 46);
	line-height: (48 / 46);

	&--secondary {
		font-size: rem(40);
	}

	@include breakpoint($breakpoint-desktop) {
		font-size: rem(110);
		line-height: (110 / 110);

		&--secondary {
			font-size: rem(46);
		}
	}
}

.h3 {
	font-size: rem(28);
	letter-spacing: letter-spacing(0.7, 28);
	line-height: (38 / 28);
	margin-bottom: rem(30);

	@include breakpoint($breakpoint-desktop) {
		font-size: rem(40);
		line-height: (48 / 40);
		margin-bottom: rem(60);
	}
}

.h4 {
	font-size: rem(20);
	letter-spacing: letter-spacing(4, 20);
	line-height: (32 / 20);

	@include breakpoint($breakpoint-desktop) {
		font-size: rem(32);
		letter-spacing: letter-spacing(9.14, 32);
		line-height: (32 / 32);
	}
}

.h5 {
	font-size: rem(16);
	line-height: 1;

	@include breakpoint($breakpoint-tablet-min) {
		font-size: rem(28);
	}
}
