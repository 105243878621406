.accordion {
	$this: &;

	&__section {
		+#{$this}__section {
			margin-top: rem(30);
		}
	}

	&__heading {
		position: relative;
		width: 100%;
		margin-bottom: 0;
		padding-right: rem(40);
		border-bottom: 1px solid currentColor;
	}

	&__button {
		text-align: left;

		&::after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}

	&__icon {
		@include size(rem(16));
		position: absolute;
		top: 50%;
		right: rem(10);
		margin-top: rem(-7);
		pointer-events: none;

		&::before,
		&::after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: rem(2);
			height: rem(2);
			margin: auto;
			background-color: currentColor;
		}

		&::before {
			width: 100%;
		}

		&::after {
			height: 100%;
			transition: transform .3s ease-in-out;
		}

	}

	&__content {
		margin-top: rem(30);
	}

	.js & {
		&__section {
			&:not(#{$this}__section--expanded) {
				#{$this}__content {
					display: none;
				}
			}

			&--expanded {
				#{$this}__icon::after {
					transform: scaleY(0);
				}
			}
		}
	}

	@include breakpoint($breakpoint-desktop) {
		&__section {
			+#{$this}__section {
				margin-top: rem(30);
			}
		}

		&__content {
			margin-top: rem(60);
		}
	}
}