//** Settings | Colors

$color-black: #000;
$color-candlelight: #fecf12;
$color-charcoal: #282828;
$color-charcoal-20: rgba($color-charcoal, 0.2);
$color-golden-sand: #ffeea0;
$color-golden-sand-lighten-12: lighten($color-golden-sand, 12);
$color-milano-red: #bb1600;
$color-milano-red-20: rgba($color-milano-red, 0.2);
$color-mustard: #ffe159;
$color-mustard-95: rgba($color-mustard, 0.95);
$color-sandstone: #716a5b;
$color-sandstone-20: rgba($color-sandstone, 0.2);
$color-sandstone-50: rgba($color-sandstone, 0.5);
$color-white: #fff;
$color-white-70: rgba($color-white, 0.7);