.memory-image {
	$this: &;
	$info-background-color: $color-golden-sand-lighten-12;
	$box-shadow-color: $color-black;

	position: relative;
	display: block;

	perspective: rem(1000);
	cursor: pointer;

	&__container {
		transform-style: preserve-3d;
	}

	&__front,
	&__back {
		transition: transform .7s ease-in-out;
		backface-visibility: hidden;
		transform-style: preserve-3d;
		box-shadow: 3px 3px 30px rgba($box-shadow-color, .2);
	}

	&__front {
		position: relative;
		display: block;
		width: 100%;
		height: auto;
		z-index: 2;
		transform: rotateY(0deg);
	}

	&__back {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		padding: rem(30);
		background: $info-background-color;
		transform: rotateY(180deg);
		overflow: auto;
		z-index: 1;
		box-sizing: border-box;

		.rich-text {
			text-align: center;
		}
	}

	&[active] {
		#{$this}__front {
			transform: rotateY(-180deg);
		}

		#{$this}__back {
			transform: rotateY(0deg);
			user-select: auto;
		}

		[data-whatintent="mouse"] &:hover,
		.no-js &:hover,
		[data-whatintent="keyboard"] &:focus {
			#{$this}__front {
				transform: rotateY(-180deg);
			}

			#{$this}__back {
				transform: rotateY(0deg);
			}
		}
	}

	@include breakpoint($breakpoint-desktop) {
		&__back {
			padding: rem(50);
		}
	}

	@media (prefers-reduced-motion: reduce) {
		&__info {
			position: relative;
			transform: none;
		}

		&[active] {

			#{$this}__front,
			#{$this}__back {
				transform: none;
			}

			[data-whatintent="mouse"] &:hover,
			.no-js &:hover,
			[data-whatintent="keyboard"] &:focus {

				#{$this}__front,
				#{$this}__back {
					transform: none;
				}
			}
		}

		[data-whatintent="mouse"] &:hover,
		.no-js &:hover,
		[data-whatintent="keyboard"] &:focus {

			#{$this}__front,
			#{$this}__back {
				transform: none;
			}
		}
	}
}