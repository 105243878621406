main {
	$header-offset-s: $spacing-header-height-s;
	$header-offset-l: $spacing-header-height-l;

	flex: 1 0 auto;
	padding-top: $header-offset-s;
	z-index: 2;

	@include breakpoint($breakpoint-desktop) {
		padding-top: $header-offset-l;
	}

	.no-js & {
		@include breakpoint($breakpoint-below-desktop) {
			padding-top: 0;
		}
	}
}