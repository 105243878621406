.icon {
	display: block;

	path {
		fill: currentColor;
	}

	&--email {
		@include size(rem(20), rem(12));
	}

	&--facebook {
		@include size(rem(8), rem(16));
	}

	&--twitter {
		@include size(rem(18), rem(14));
	}

	&--whatsapp {
		@include size(rem(20));
	}

	&--share {
		@include size(rem(15), rem(17));
	}

	&--chevron {
		@include size(rem(17), rem(30));

		path {
			fill: none;
			stroke: currentColor;
		}
	}

	&--book,
	&--photo {
		opacity: .5;

		path {
			fill: none;
			stroke: currentColor;
		}
	}

	&--book {
		@include size(rem(55), rem(49));


	}

	&--photo {
		@include size(rem(55), rem(43));
	}
}