//** Organisms | Hero

.canvas3d {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	&__canvas {
		position: fixed;
		top: 0;
		left: 0;
	}

	// .canvasUI {
	// 	position: fixed;
	// 	top: 0;
	// 	left: 0;
	// 	pointer-events: none;
	// }

	&__line-svg {
		top: 0;
		left: 0;
		position: absolute;
		pointer-events: none;
	}

	&__prompt {
		position: absolute;
		bottom: 46%;
		text-align: center;
		margin: auto;
		left: 0;
		right: 0;
		font-family: 'baskerville-display-pt', 'Times New Roman', Times, serif;
		font-size: 21px;
		padding-top: 15px;
		transition: 1s;
		opacity: 0;
		pointer-events: none;

		&--visible {
			opacity: 1;
		}
	}
}