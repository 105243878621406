.explore-list {

	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(rem(200), 1fr));
	grid-gap: .5rem 1rem;
	margin: rem(40) auto;
	max-width: rem(1273);

	&__item {

	}

	&__link {
		text-decoration: underline;
	}

	@include breakpoint($breakpoint-above-mobile) {
		padding-left: rem(40);
		padding-right: rem(40);
	}

	.js & {
		opacity: 0;
		pointer-events: none;
	}
}