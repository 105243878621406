.album {
	$this: &;

	position: relative;
	max-width: 100%;
	margin-top: rem(90);
	padding-bottom: rem(90);
	overflow: hidden;
	z-index: 1;

	&__content,
	&__images {
		@include margin-x(auto);

		max-width: columns(10);
	}

	&__images {
		user-select: none;

		+ #{$this}__cta {
			margin-top: rem(40);
		}
	}

	&__content {
		margin-bottom: rem(40);
	}

	@include breakpoint($breakpoint-above-mobile) {
		&__content {
			display: flex;
			justify-content: space-between;
			margin-bottom: rem(80);
		}

		&__heading {
			margin-bottom: 0;
		}

		&__text {
			flex: 0 0 50%;
			padding-left: rem(20);
		}

		&__images + &__cta {
			margin-top: rem(80);
		}
	}

	@include breakpoint($breakpoint-desktop) {
		margin-top: rem(150);
		padding-bottom: rem(150);

		&__content {
			max-width: columns(8);
			margin-bottom: rem(150);
		}

		&__text {
			margin-top: rem(27);
		}

		&__images {
			max-width: columns(8);

			+ #{$this}__cta {
				top: rem(150);
			}
		}
	}

	@include breakpoint($breakpoint-retina) {
		&__content,
		&__images {
			max-width: columns(6);
		}

		&__text {
			padding-left: 0;
		}
	}
}