.search-result {
	display: flex;
	align-items: center;

	&__image {
		display: block;
		width: rem(60);
		height: auto;
	}

	&__link {
		padding-left: rem(30);
	}

	@include breakpoint($breakpoint-tablet-min) {
		&__link {
			font-size: rem(40);
			line-height: (48 / 40);
		}
	}
}