.mc-modal {
	$this: &;
	$modal-color: $color-mustard-95;
	$modal-container-color: $color-golden-sand;

	&__container {
		position: relative;
		width: columns(11);
		max-width: rem(950);
		max-height: 90%;
		background-color: $modal-container-color;
		overflow: auto;
	}

	&__content {
		padding: rem(60) rem(20) rem(20);
	}

	&__close {
		position: absolute;
		top: rem(10);
		right: rem(10);
		display: inline-flex;
		align-items: center;
	}

	&__close-icon {
		position: relative;
		display: block;
		width: rem(12);
		height: rem(12);
		margin-left: rem(9);

		&::after,
		&::before {
			content: "";
			position: absolute;
			right: 0;
			top: 50%;
			display: block;
			width: rem(12);
			height: rem(2);
			background-color: currentColor;
			transform-origin: center;
		}

		&::after {
			transform: translateY(-50%) rotate(45deg)
		}

		&::before {
			transform: translateY(-50%) rotate(-45deg)
		}
	}

	&__overlay {
		display: none;

		&--active {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			background-color: $modal-color;
			z-index: 9999;
		}
	}

	@include breakpoint($breakpoint-above-mobile) {
		&__close {
			top: rem(30);
			right: rem(30);
		}

		&__content {
			padding: rem(80);
		}
	}
}