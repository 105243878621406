.site-header {
	$header-background-color: $color-golden-sand;
	$header-transition-in-duration: .7;
	$header-transition-out-duration: .6;

	position: fixed;
	top: 0;
	right: 0;
	left: 0;

	display: flex;
	width: 100%;
	z-index: 5;

	transition: background-color .25s ease-in-out;

	&__logo {
		display: block;
	}

	&__nav-placeholder {
		display: none;
	}

	.no-js & {
		position: relative;
	}

	#{$tribute-page} &,
	#{$default-page} & {
		background-color: $header-background-color;
	}

	&__flavour-text {
		color: $color-sandstone;
		font-style: italic;
		margin: 0 30px;
	}

	&__featured-cta .button--text {
		color: $color-sandstone;
	}

	@include breakpoint($breakpoint-below-desktop) {
		flex-wrap: wrap;

		&__mob-top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding: 20px 30px;
			z-index: 2;
		}

		&__mob-bottom {
			display: flex;
			flex-direction: column;
			width: 100%;
			padding: 0 30px;
			overflow: auto;
			background-color: $header-background-color;
			z-index: 1;
		}

		&__nav-container {
			display: flex;
			flex-direction: column;
			flex: 1 0 auto;
			justify-content: center;
			padding-bottom: 30px;
		}

		&__nav {
			margin-top: 20px;
		}

		&__additional-nav {
			bottom: 20px;
			position: absolute;
			right: 30px;
			z-index: 1;
		}

		&__featured-cta {
			display: flex;
			flex-direction: column;
			align-items: center;
			order: -1;
			margin: 0 auto;
			text-align: center;

			.button {
				~ .mc-modal,
				~ mc-button {
					display: block;
					margin-top: 15px;
				}
			}
		}

		&__edit-cta {
			margin: 20px auto 0;
			text-align: center;
		}

		&__search {
			padding-bottom: rem(75);
		}

		.js & {
			&__mob-top::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 100%;
				height: 100%;
				background-color: $header-background-color;
				opacity: 0;
				z-index: -1;
				pointer-events: none;
				transition: opacity #{$header-transition-out-duration/2}s ease-in-out #{$header-transition-out-duration/2}s;
			}

			&__mob-bottom {
				position: absolute;
				left: 0;
				top: 0;
				transform: translateY(-100%);
				opacity: 0;

				transition: opacity #{$header-transition-out-duration/2}s #{$header-transition-out-duration/2}s ease-in-out, transform 0s #{$header-transition-out-duration}s
			}

			&__nav-container ,
			&__search {
				opacity: 0;
				transition: opacity #{$header-transition-out-duration/2}s ease-in-out;
			}
		}

		#{$site-nav-open} & {
			&__mob-top::after {
				opacity: 1; //$header-background-color;
				transition: opacity #{$header-transition-in-duration / 2}s ease-in-out;
			}

			&__mob-bottom {
				opacity: 1;
				transform: translateY(0);
				transition: opacity #{$header-transition-in-duration / 2}s 0s ease-in-out, transform 0s 0s;
			}

			&__nav-container,
			&__search {
				opacity: 1;
				transition: opacity #{$header-transition-in-duration / 2}s ease-in-out #{$header-transition-in-duration / 2}s;
			}
		}
	}

	@include breakpoint($breakpoint-desktop) {
		justify-content: space-between;
		padding: rem(60);

		&__mob-bottom {
			flex: 1 1 auto;
		}

		&__nav-container {
			align-items: center;
			display: flex;
			justify-content: flex-end;
			height: 100%;
		}

		&__nav-placeholder {
			display: block;
			width: rem(30);
			height: rem(2);
			background-color: currentColor;
			margin: auto;
		}

		&__nav {
			margin: 0 auto;
			height: rem(51); // This is set to ensure that the navigation lines up with the featured cta
		}

		&__featured-cta {
			align-items: center;
			display: flex;
			justify-content: flex-end;

			.button + mc-button,
			.button + mc-modal {
				margin-left: 15px;
			}
		}

		&__search-link {
			@include size(rem(44));

			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 rem(25) 0 rem(10);
			color: $color-sandstone;

			svg {
				@include size(19);
			}
		}
	}

	// Make main navigation appear centred.
	@include breakpoint(1200px) {
		&__mob-top,
		&__featured-cta {
			min-width: 250px;
		}
	}
}
