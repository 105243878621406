.eulogy {
	$eulogy-background-color: $color-mustard;

	position: relative;
	padding-top: rem(85);
	padding-bottom: rem(85);

	background-color: $eulogy-background-color;

	&__content {
		max-width: columns(10);
		margin: 0 auto;
	}

	&__heading {
		margin-bottom: rem(60);
	}

	&__summary {
		max-width: rem(626);
	}

	&__date {
		@include margin-y(rem(40));
	}

	&__letter {
		display: none;
	}

	@include breakpoint($breakpoint-above-mobile) {
		&__rich-text {
			max-width: rem(680);
		}

		&__letter {
			position: absolute;
			top: 0;
			left: 50%;
			display: block;
			width: columns(8);
			transform: translateY(-25%);
			opacity: .03;
		}
	}

	@include breakpoint($breakpoint-desktop) {
		padding-top: rem(200);
		padding-bottom: rem(200);

		&__content {
			max-width: columns(8);
		}

		&__date {
			margin-top: rem(80);
			margin-bottom: rem(60);
		}

		&__rich-text {
			max-width: rem(790);
		}
	}
}