.list {
	$this: &;
	$border-color: $color-sandstone-50;

	list-style: none;
	padding: 0;

	&__item {
		margin: 0 0 16px;
	}

	&--unordered {
		list-style: disc;
		padding-left: rem(18);
	}

	&--ordered {
		list-style: decimal;
		padding-left: rem(18);
	}

	&--bordered {
		#{$this}__item {
			padding-bottom: rem(30);
			border-bottom: 1px solid $border-color;
			margin-bottom: 0;

			&:not(:first-child) {
				margin-top: rem(30);
			}
		}
	}
}
