//** Organisms | Rich Text

.rich-text {
	$anchor-hover-color: $color-candlelight;

	text-align: left;
	word-break: break-word;

	&--drop-cap {
		p:first-child {
			&::first-letter {
				float: left;
				position: relative;
				display: block;
				margin-top: em(8, 51);
				padding-right: em(6, 51);
				font-size: rem(52);
				letter-spacing: letter-spacing(.92, 82);
				line-height: (36 / 52);
				text-transform: uppercase;
			}
		}
	}

	&--center {
		text-align: center;
	}

	h1 {
		@extend .h1;
	}

	h2 {
		@extend .h2;
	}

	h3 {
		@extend .h3;
	}

	p {
		@extend .paragraph;
	}

	ol,
	ul {
		@extend .list;

		li {
			@extend .list__item;
		}
	}

	ol {
		@extend .list--ordered;
	}

	ul {
		@extend .list--unordered;
	}

	a:not([class]) {
		text-decoration: underline;
		transition: background-color .2s ease-in-out;

		[data-whatintent="mouse"] &:hover,
		.no-js &:hover,
		[data-whatintent="keyboard"] &:focus {
			background-color: $anchor-hover-color;
		}
	}

	@include breakpoint($breakpoint-desktop) {
		&--drop-cap {
			p:first-child {
				&::first-letter {
					font-size: rem(60);
				}
			}
		}

		&__grid-2 {
			display: flex;
			margin: rem(30) rem(-20) 0;

			.hint-card:nth-child(-n+2) {
				margin-top: 0;
			}
		}

		&__col {
			width: 50%;
			margin-left: rem(20);
			margin-right: rem(20);
		}
	}
}