.error-page {
	background-image: url(/assets/images/404.jpg);
	background-position: center bottom;
	background-repeat: no-repeat;
	background-size: auto 100%;

	main {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__container {
		@include margin-x(auto);
		max-width: 40em;
		text-align: center;
	}

	&__rich-text {
		text-align: inherit;

		h1 + h2 {
			font-size: rem(25);
			margin-top: rem(-30);

			@include breakpoint($breakpoint-desktop) {
				font-size: rem(38);
				margin-top: rem(-90);
			}
		}
	}

	&__links {
		margin-top: rem(40);
	}
}