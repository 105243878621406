
.js#{$fixed-window}  {
	position: fixed;
	height: 100%;
	width: 100%;
	overflow-y: scroll;

	@include breakpoint($breakpoint-desktop) {
		&#{$site-nav-open} {
			position: relative;
			height: auto;
			overflow: auto;
		}
	}
}