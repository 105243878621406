.date-text {
	font-size: rem(18);
	letter-spacing: letter-spacing(.32, 18);
	line-height: (29 / 18);

	@include breakpoint($breakpoint-desktop) {
		font-size: rem(20);
		letter-spacing: letter-spacing(.35, 20);
		line-height: (29 / 20);
	}
}