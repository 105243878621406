.drag-drop-input {
	$this: &;
	$border-color: $color-sandstone;
	$background-color: $color-candlelight;

	position: relative;
	display: flex;
	justify-content: center;
	padding: rem(30) rem(20);
	text-align: center;

	&:not(:first-child) {
		margin-top: rem(40);
	}

	&::before {
		content: "";
		@include absolute-fill();

		border: rem(2) dashed $border-color;
		background-color: $background-color;
		opacity: .5;
		transition: opacity .3s ease-in-out, border-color .3s ease-in-out;
	}

	&__area {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__label {
		position: relative;
		display: block;
		font-size: rem(16);
		line-height: (36/22);
		letter-spacing: letter-spacing(.39, 22);
		margin-bottom: rem(15);
	}

	&__input {
		@include absolute-fill();
		opacity: 0;
		z-index: 1;

		[data-whatintent="keyboard"] &:focus + #{$this}__button {
			.button__bg {
				transform: translateX(-17.5%);
				opacity: 1;
				transition: transform 0.56s cubic-bezier(0.44, 0.15, 0.74, 0.45);

				&::before {
					transform: translateY( -23%);
					transition: transform 0.56s cubic-bezier(0, 0.51, 0.78, 0.92);
				}
			}
		}
	}

	&__error {
		position: absolute;
		bottom: rem(9);
		left: 50%;
		transform: translateX(-50%);
		width: 90%;
		font-size: rem(12);
		opacity: 0;
		pointer-events: none;
		color: $color-milano-red;

		transition: opacity .3s ease-in-out;
	}

	&--highlight {
		&::before {
			opacity: 1;
		}

		#{$this}__button {
			pointer-events: none;
		}
	}

	&--invalid {
		&::before {
			border-color: $color-milano-red;
		}

		#{$this}__error {
			opacity: 1;
			pointer-events: auto;
		}
	}

	@include breakpoint($breakpoint-mobile) {
		&__button {
			padding-left: em(15, 12);
			padding-right: em(15, 12);
		}
	}

	@include breakpoint($breakpoint-above-mobile) {
		padding: rem(30) rem(30) rem(40);

		&__label {
			font-size: rem(22);
		}

		&__error {
			font-size: rem(16);
		}
	}
}