.tribute-footer {
	position: relative;
	text-align: center;

	&__content {
		position: relative;
		padding-top: rem(90);
		padding-bottom: rem(70);
		z-index: 2;
	}

	&__heading {
		@include font($font-orpheuspro);
		@include margin-x(auto);

		width: columns(10);
		max-width: rem(630);

		font-size: rem(40);
		letter-spacing: letter-spacing(1, 40);
		line-height: (48 / 40);
		margin-bottom: rem(20);
	}

	&__bg {
		@include padding-x(rem(30));

		position: absolute;
		top: 50%;
		left: 50%;
		display: flex;
		align-items: center;
		width: 100%;
		max-width: rem(364);
		transform: translate(-50%, -50%);
		overflow: hidden;

		img {
			width: 100%;
			height: auto;
		}
	}

	@include breakpoint($breakpoint-desktop) {
		&__content {
			@include padding-y(rem(140));
		}

		&__heading {
			font-size: rem(60);
			letter-spacing: letter-spacing(1, 60);
			line-height: (68 / 60);
			margin-bottom: rem(40);
		}
	}
}