.footer-nav {
	$footer-nav-color: $color-sandstone;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	list-style: none;

	&__item {
		line-height: 0;
	}

	.button--text {
		color: $footer-nav-color;
	}

	@include breakpoint($breakpoint-below-desktop) {
		justify-content: flex-end;

		&__item {
			.share-links {
				margin-right: -15px;
			}
		}
	}
}