.swiper-container {
	position: relative;
	display: block;
	padding: 0;
	margin: 0 auto;
	list-style: none;
	/* Fix of Webkit flickering */
	z-index: 1;
}

.swiper-container-no-flexbox {
	.swiper-slide {
		float: left;
	}
}

.swiper-container-vertical>.swiper-wrapper {
	flex-direction: column;
}

.swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	transition-property: transform;
	// box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
	transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow>.swiper-wrapper {
	flex-wrap: wrap;
}

.swiper-container-free-mode>.swiper-wrapper {
	transition-timing-function: ease-out;
	margin: 0 auto;
}

.swiper-slide {
	flex-shrink: 0;
	width: 100%;
	position: relative;
	transition-property: transform;
}

.swiper-slide-invisible-blank {
	visibility: hidden;
}

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
	height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
	align-items: flex-start;
	transition-property: transform, height;
}


/* IE10 Windows Phone 8 Fixes */
.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal>.swiper-wrapper {
	touch-action: pan-y;
}

.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical>.swiper-wrapper {
	touch-action: pan-x;
}

.swiper-scrollbar-cursor-drag,
.swiper-slide-zoomed {
	cursor: move;
}