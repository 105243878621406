.add-memory {
	display: block;

	&__view {
		display: none;

		&--active {
			display: block
		}

		&--input {
			text-align: left;
		}
	}
}