//** Atoms | Html

html {
	$html-background-color: $color-golden-sand;
	$html-font-color: $color-charcoal;

	@include font($font-default);

	background-color: $html-background-color;
	color: $html-font-color;
	height: 100%;
	font-size: 100%;

	@include breakpoint($breakpoint-retina) {
		font-size: calc(16 / 1920 * 100vw);
	}
}