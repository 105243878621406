.search-results {
	$this : &;

	&:last-child {
		padding-bottom: rem(30);
	}

	#{$this}__item {
		border-color: rgba(182, 171, 131, .4);
	}

	@include breakpoint($breakpoint-desktop) {
		&:last-child {
			padding-bottom: rem(50);
		}

		#{$this}__item {
			padding-bottom: rem(50);

			&:not(:first-child) {
				margin-top: rem(50);
			}
		}
	}
}