//** Mixins | Button Reset

@mixin button-reset {
	background-color: transparent;
	border: 0;
	border-radius: 0;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	padding: 0;
	text-transform: inherit;

	&:not(:disabled) {
		cursor: pointer;
	}
}