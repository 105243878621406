@mixin reset {

	html,
	:root {
		box-sizing: border-box;
	}

	html {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		min-height: 100%;
	}

	body {
		margin: 0;
		min-height: 100%;
	}

	* {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

		&,
		&::before,
		&::after {
			box-sizing: inherit;
		}
	}

	html,
	body,
	img,
	abbr,
	acronym {
		border: 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: 100%;
		font-weight: normal;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	th,
	code,
	cite,
	caption {
		font-weight: normal;
		font-style: normal;
		text-align: left;
	}

	address {
		font-style: normal;
	}

	textarea {
		resize: vertical;
		-webkit-appearance: none;
		-webkit-border-radius: 0;
		border-radius: 0;
	}

	fieldset {
		// Changes fieldsets to remove min-content min-width.
		margin: 0;
		padding: 0;
		min-width: auto;
		border: 0;
	}

	input {
		-webkit-appearance: none;
		-webkit-border-radius: 0;
		border-radius: 0;

		&:focus,
		&[type="search"]:focus {
			outline-offset: -2px;
		}

		&[type="search"] {
			appearance: none;
			box-sizing: border-box;
		}

		&[type="number"] {
			-moz-appearance: textfield !important;

			&::-webkit-inner-spin-button,
			&::-webkit-outer-spin-button {
				display: none;
			}
		}

		&:-webkit-autofill,
		&:-webkit-autofill:focus {
			-webkit-transition-delay: 99999s;
		}
	}

	select {
		cursor: pointer;
		appearance: none;
		-webkit-border-radius: 0;
		border-radius: 0;

		&::-ms-expand {
			display: none;
		}
	}

	::-ms-clear {
		display: none;
	}

	::-webkit-clear-button {
		display: none;
	}

	img,
	video {
		max-width: 100%;
	}

	img {
		object-fit: cover;
		font-family: "object-fit: cover;";
	}

	picture {
		display: block;
	}

	figure,
	blockquote {
		margin: 0;
	}

	summary {
		// Removes the arrow from the <summary> element.
		display: block;
	}

	button {
		font-size: inherit;
	}

	a {
		color: currentColor;
		text-decoration: none;

		&[href^=tel] {
			color: inherit;
			text-decoration: inherit;
			font-size: inherit;
			font-style: inherit;
			font-weight: inherit;
		}
	}

	ul,
	ol {
		padding: 0;
		margin: 0;
	}

	svg {
		fill: currentColor;
	}

	template {
		display: none;
	}

	canvas {
		display: block;
		outline: none;
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		/* mobile webkit */
	}
}