
.form-page {
	$this: &;

	display: block;

	&__heading {
		+.form-field {
			margin-top: 0;
		}

		&--center {
			text-align: center;
		}
	}

	&__text {
		margin-bottom: rem(20);

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		legend {
			text-align: center;
		}

		p {
			@include margin-x(auto);
		}
	}

	&__disclaimer {
		font-size: rem(12);
		margin-top: 2em;
	}

	&__controls {
		display: flex;
		justify-content: space-between;
		margin-top: rem(30);
	}

	@include breakpoint($breakpoint-desktop) {
		&__heading {
			margin-bottom: rem(70);
		}

		&__double-input {
			display: flex;
			justify-content: space-between;

			margin-left: rem(-10);
			margin-right: rem(-10);

			.form-field {
				margin-left: rem(10);
				margin-right: rem(10);
			}
		}
	}
}