//** Atoms | Buttons

.button {
	$this: &;
	$button-padding: em(20, 12) em(30, 12);
	$button-border-color: $color-sandstone-50;
	$button-text-padding-vertical: em(10, 12);
	$button-text-padding-horizontal: em(15, 12);
	$button-text-hover-color: $color-charcoal;

	@include button-reset;
	@include font($font-button);
	position: relative;
	display: inline-block;
	padding: $button-padding;
	margin: 0;

	border: 1px solid $button-border-color;
	overflow: hidden;
	transform: rotateZ(0); // Fixes Safari rendering issue.
	z-index: 1;
	user-select: none;

	&__bg {
		@include size(480%, 445%);

		content: "";
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		z-index: -1;
		pointer-events: none;

		transform: translateX(-81.5%);
		opacity: 0;
		transition: opacity 0.2s ease-in-out, transform 0s 0.2s;
		will-change: transform;

		&::before {
			@include absolute-fill();

			content: "";
			background-image: url("/assets/images/button-petal.png");
			background-repeat: no-repeat;
			background-position: top left;
			background-size: 100% 100%;
			transform: translateY( -60%);
			transition: transform 0s 0.2s;
			z-index: -1;
		}
	}

	[data-whatintent="mouse"] &:hover,
	.no-js &:hover,
	[data-whatintent="keyboard"] &:focus {
		#{$this}__bg {
			transform: translateX(-17.5%);
			opacity: 1;
			transition: transform 0.56s cubic-bezier(0.44, 0.15, 0.74, 0.45);

			&::before {
				transform: translateY( -23%);
				transition: transform 0.56s cubic-bezier(0, 0.51, 0.78, 0.92);
			}
		}
	}

	&--text {
		padding: $button-text-padding-vertical $button-text-padding-horizontal;
		border: 0;
		letter-spacing: letter-spacing(3, 12);
		transition: color 0.2s ease-in-out;

		&__bg {
			display: none;
		}

		&::after {
			content: "";
			position: absolute;
			bottom: ($button-text-padding-vertical / 2);
			left: $button-text-padding-horizontal;
			right: $button-text-padding-horizontal;
			display: block;
			height: rem(1);
			background-color: currentColor;
			opacity: 0;
			transition: opacity 0.2s ease-in-out;
		}

		[data-whatintent="mouse"] &:hover,
		.no-js &:hover,
		[data-whatintent="keyboard"] &:focus {
			background-color: transparent;
			color: $button-text-hover-color;

			&::after {
				opacity: 1;
			}
		}
	}

	&--secondary {
		padding: rem(15);
		border: 1px solid currentColor;
		transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out;

		[data-whatintent="mouse"] &:hover,
		.no-js &:hover,
		[data-whatintent="keyboard"] &:focus {
			color: $color-white;
			border-color: $color-charcoal;
			background-color: $color-charcoal;
		}
	}

	&--warn {
		color: $color-milano-red;

		[data-whatintent="mouse"] &:hover,
		.no-js &:hover,
		[data-whatintent="keyboard"] &:focus {
			color: $color-white;
			border-color: $color-milano-red;
			background-color: $color-milano-red;
		}
	}
}

.button-reset {
	@include button-reset;
}

mc-button {
	display: inline-flex;
}