.hide {
	&-js {
		.js & {
			display: none;
		}
	}

	&-no-js {
		.no-js & {
			display: none;
		}
	}

	&-s {
		@include breakpoint($breakpoint-below-desktop) {
			display: none;
		}
	}

	&-l {
		@include breakpoint($breakpoint-desktop) {
			display: none;
		}
	}
}