.memory {

	&__info {
		display: flex;
		align-items: flex-end;
		margin-top: rem(30);
	}

	&__img {
		width: rem(60);
		height: auto;
	}

	&__date {
		margin-left: rem(20);
		transform: translateY(rem(8));

		&:first-child {
			margin-left: 0;
		}
	}

	@include breakpoint($breakpoint-desktop) {
		&__date {
			margin-left: rem(30)
		}

		&__info {
			margin-top: rem(40);
		}
	}
}