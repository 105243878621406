.skip-links {
	$skip-link-background-color: $color-candlelight;

	position: absolute;
	left: 0;
	top: 20vh;
	list-style: none;
	padding: 0;
	pointer-events: none;
	overflow: hidden;

	&__link {
		pointer-events: auto;
		transform: translateX(-102%);
		transition: transform .3s ease-in-out;
		background-color: $skip-link-background-color;

		&:focus {
			transform: translateX(0);
		}
	}
}