.pagination {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem(30) 0;
	margin: 0 rem(-14);

	&__link {
		@include size(rem(44));
		display: flex;
		align-items: center;
		justify-content: center;

		&--disabled {
			opacity: .4;
		}
	}


	@include breakpoint($breakpoint-tablet-min) {
		padding: rem(50) 0;
	}
}