//** Functions | Units

$browser-context: 16;

@function em($px, $context: $browser-context) {
	@return ($px / $context) * 1em;
}

@function rem($px, $context: $browser-context) {
	@return ($px / $context) * 1rem;
}