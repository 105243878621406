
.share-links {
	$this: &;
	$share-link-color: $color-sandstone;
	$share-link-hover-color: $color-charcoal;
	$share-link-hover-background-color: $color-candlelight;
	$share-link-toggle-mobile-color: $color-charcoal;
	$share-link-list-background-color: $color-golden-sand;

	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $share-link-color;

	&--active {
		#{$this}__list {
			opacity: 1;
			transform: translateY(0%);
			transition-duration: .5s;
		}
	}

	&__container {
		position: absolute;
		left: 50%;
		bottom: 100%;

		transform: translateX(-50%);
		overflow: hidden;
		pointer-events: none;
	}

	&__list {
		position: relative;
		display: flex;
		align-items: center;
		list-style: none;

		flex-direction: column;
		margin-bottom: rem(10);
		transform: translateY(100%);
		opacity: 0;
		z-index: 1;

		background-color: $share-link-list-background-color;
		pointer-events: auto;

		transition: opacity .2s ease-in-out, transform .2s ease-in-out;
	}

	&__link {
		@include size(rem(44));
		display: flex;
		align-items: center;
		justify-content: center;
		transition: color .2s ease-in-out, background-color .2s ease-in-out;

		[data-whatintent="mouse"] &:hover,
		.no-js &:hover,
		[data-whatintent="keyboard"] &:focus {
			color: $share-link-hover-color;
			background-color: $share-link-hover-background-color;
		}
	}

	&__toggle {
		z-index: 2;
	}
}