text-revealer {
	.button {
		margin-top: rem(40);
	}

	@include breakpoint($breakpoint-desktop) {
		.button {
			margin-top: rem(75);
		}
	}
}