@import url('https://unpkg.com/tailwindcss@^2.0/dist/tailwind.min.css');
.contact-input-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 2px solid #ccc;
  padding: 5px;
}

.contact-tag {
  display: inline-flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 3px;
  padding: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.contact-tag button {
  background: none;
  border: none;
  margin-left: 5px;
  cursor: pointer;
}

.contact-input {
  flex: 1;
  min-width: 120px;
  border: none;
  outline: none;
  padding: 5px;
  background: transparent;
}

  .floating-label {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 0.5rem;
    pointer-events: none;
    transition: all 0.2s ease-out;
    transform: translateY(1.2rem);
    color: #6b7280; /* Gray color */
  }
  .floating-input:focus + .floating-label,
  .floating-input:not(:placeholder-shown) + .floating-label {
    transform: translateY(-1rem);
    font-size: 0.75rem;
    color: #4b5563; /* Darker gray color */
  }
.input-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .input-header {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #333;
  }
  
  .input-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .input-group label {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .input-group input,
  .input-group textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    width: 100%;
  }
  
  .submit-button {
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    align-self: center;
    margin-top: 20px;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  