.memories {
	$this: &;

	position: relative;

	&__container {
		@include margin-x(auto);

		width: columns(10);
	}

	&:not(:last-child) {
		padding-bottom: rem(80)
	}

	&__heading {
		text-align: right;
	}

	&__list {
		list-style: none;

		+ #{$this}__cta {
			margin-top: rem(40);
		}
	}

	&__item {
		margin-top: rem(60);
	}

	&__letter {
		display: none;
	}

	&__petal {
		display: none;
	}

	@include breakpoint($breakpoint-above-mobile) {
		&__container {
			width: columns(10);
		}

		&__heading {
			max-width: rem(620);
			margin-bottom: rem(100);
			margin-left: auto;
		}

		&__list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		&__item {
			margin-top: 0;
			width: calc(#{columns(4, 8)} - #{rem(30)});

			&:nth-child(even) {
				margin-top: rem(80);
			}
		}

		&__letter {
			position: absolute;
			top: rem(-100);
			left: columns(3);
			display: block;
			width: columns(9);
			opacity: .03;
			z-index: -1;
		}
	}

	@include breakpoint($breakpoint-desktop) {
		&__container {
			width: columns(8);
		}

		&:not(:last-child) {
			padding-bottom: rem(200)
		}

		&__heading {
			margin-bottom: rem(140);
		}

		&__item {
			width: calc(#{columns(3, 8)} - #{rem(10)});

			&:nth-child(even) {
				margin-top: rem(180);
			}

			&:nth-child(odd):not(:first-child) {
				margin-top: rem(80);
			}
		}

		&__letter {
			top: rem(-300);
			left: columns(3);
		}

		&__petal {
			position: absolute;
			display: block;
			z-index: -1;

			&--heading {
				top: rem(-60);
				left: columns(2.6);
				width: rem(215);
			}

			&--left {
				top: 50%;
				left: rem(-200);
				width: rem(331);
			}

			&--bottom {
				right: columns(2.3);
				bottom: rem(190);
				width: rem(264);
			}
		}

		&__list + &__cta {
			margin-top: rem(80);
		}
	}

	@include breakpoint($breakpoint-retina) {
		&__letter {
			top: rem(-400);
			left: columns(5);
			width: columns(9);
		}

		&__list + &__cta {
			margin-top: rem(150);
		}
	}
}