$bar-height: 16;
$bar-scale: (1 / $bar-height);

.audio-toggle {
	$this: &;

	@include button-reset;
	@include size(rem(44));

	display: flex;
	align-items: center;
	justify-content: center;

	&__circle {
		@include size(29px);
		display: flex;
		align-items: center;
		justify-content: center;

		border-radius: 50%;
		border: 1px solid currentColor;
	}

	&__svg {
		@include size(10px, 10.7px);
		display: block;
	}

	&__bar {
		fill: currentColor;

		animation-duration: 1s;
		animation-iteration-count: 1;
		animation-fill-mode: forwards;
		animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);

		&:nth-child(1) {
			transform: translate(10px, 15px) scale(1, 1) translate(-10px, -15px);
			animation-name: paused-anim-5;
		}

		&:nth-child(2) {
			transform: translate(13px, 15px) scale(1, 1) translate(-13px, -15px);
			animation-name: paused-anim-4;
		}

		&:nth-child(3) {
			transform: translate(4px, 15px) scale(1, 1) translate(-4px, -15px);
			animation-name: paused-anim-3;
		}

		&:nth-child(4) {
			transform: translate(1px, 15px) scale(1, 1) translate(-1px, -15px);
			animation-name: paused-anim-2;
		}

		&:nth-child(5) {
			transform: translate(7px, 15px) scale(1, 1) translate(-7px, -15px);
			animation-name: paused-anim-1;
		}
	}

	&--playing {
		#{$this}__bar {
			animation-duration: 3s;
			animation-iteration-count: infinite;
			animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);

			&:nth-child(1) {
				transform: translate(10px, 15px) scale(1, 1) translate(-10px, -15px);
				animation-name: playing-anim-5;
			}

			&:nth-child(2) {
				transform: translate(13px, 15px) scale(1, 1) translate(-13px, -15px);
				animation-name: playing-anim-4;
			}

			&:nth-child(3) {
				transform: translate(4px, 15px) scale(1, 1) translate(-4px, -15px);
				animation-name: playing-anim-3;
			}

			&:nth-child(4) {
				transform: translate(1px, 15px) scale(1, 1) translate(-1px, -15px);
				animation-name: playing-anim-2;
			}

			&:nth-child(5) {
				transform: translate(7px, 15px) scale(1, 1) translate(-7px, -15px);
				animation-name: playing-anim-1;
			}
		}
	}

	@include breakpoint($breakpoint-desktop) {
		&__circle {
			@include size(rem(42));
		}

		&__svg {
			@include size(rem(14), rem(15));
		}
	}
}

// Playing Animation
@keyframes playing-anim-1 {
	0% {
		transform: translate(7px, 15px) scale(1, 1) translate(-7px, -15px);
	}
	16.67% {
		transform: translate(7px, 15px) scale(1, 0.3) translate(-7px, -15px);
	}
	22.22% {
		transform: translate(7px, 15px) scale(1, 1.2) translate(-7px, -15px);
	}
	34.44% {
		transform: translate(7px, 15px) scale(1, 0.3) translate(-7px, -15px);
	}
	40% {
		transform: translate(7px, 15px) scale(1, 1.6) translate(-7px, -15px);
	}
	52.22% {
		transform: translate(7px, 15px) scale(1, 0.2) translate(-7px, -15px);
	}
	57.78% {
		transform: translate(7px, 15px) scale(1, 0.7) translate(-7px, -15px);
	}
	66.67% {
		transform: translate(7px, 15px) scale(1, 1) translate(-7px, -15px);
	}
	71.11% {
		transform: translate(7px, 15px) scale(1, 1.5) translate(-7px, -15px);
	}
	100% {
		transform: translate(7px, 15px) scale(1, 1) translate(-7px, -15px);
	}
}

@keyframes playing-anim-2 {
	0% {
		transform: translate(1px, 15px) scale(1, 1) translate(-1px, -15px);
	}
	5.56% {
		transform: translate(1px, 15px) scale(1, 0.1) translate(-1px, -15px);
	}
	18.89% {
		transform: translate(1px, 15px) scale(1, 1.2) translate(-1px, -15px);
	}
	31.11% {
		transform: translate(1px, 15px) scale(1, 0.2) translate(-1px, -15px);
	}
	41.11% {
		transform: translate(1px, 15px) scale(1, 0.9) translate(-1px, -15px);
	}
	73.33% {
		transform: translate(1px, 15px) scale(1, 0.3) translate(-1px, -15px);
	}
	81.11% {
		transform: translate(1px, 15px) scale(1, 1.3) translate(-1px, -15px);
	}
	100% {
		transform: translate(1px, 15px) scale(1, 1) translate(-1px, -15px);
	}
}

@keyframes playing-anim-3 {
	0% {
		transform: translate(4px, 15px) scale(1, 1) translate(-4px, -15px);
	}
	12.22% {
		transform: translate(4px, 15px) scale(1, 2.4) translate(-4px, -15px);
	}
	25.56% {
		transform: translate(4px, 15px) scale(1, 2) translate(-4px, -15px);
	}
	48.89% {
		transform: translate(4px, 15px) scale(1, 2.2) translate(-4px, -15px);
	}
	54.44% {
		transform: translate(4px, 15px) scale(1, 0.2) translate(-4px, -15px);
	}
	62.22% {
		transform: translate(4px, 15px) scale(1, 1) translate(-4px, -15px);
	}
	77.78% {
		transform: translate(4px, 15px) scale(1, 1.5) translate(-4px, -15px);
	}
	100% {
		transform: translate(4px, 15px) scale(1, 1) translate(-4px, -15px);
	}
}

@keyframes playing-anim-4 {
	0% {
		transform: translate(13px, 15px) scale(1, 1) translate(-13px, -15px);
	}
	10% {
		transform: translate(13px, 15px) scale(1, 4.9) translate(-13px, -15px);
	}
	14.44% {
		transform: translate(13px, 15px) scale(1, 2) translate(-13px, -15px);
	}
	27.78% {
		transform: translate(13px, 15px) scale(1, 3.9) translate(-13px, -15px);
	}
	42.22% {
		transform: translate(13px, 15px) scale(1, 0.2) translate(-13px, -15px);
	}
	50% {
		transform: translate(13px, 15px) scale(1, 4.9) translate(-13px, -15px);
	}
	68.89% {
		transform: translate(13px, 15px) scale(1, 0.5) translate(-13px, -15px);
	}
	93.33% {
		transform: translate(13px, 15px) scale(1, 3) translate(-13px, -15px);
	}
	100% {
		transform: translate(13px, 15px) scale(1, 1) translate(-13px, -15px);
	}
}

@keyframes playing-anim-5 {
	0% {
		transform: translate(10px, 15px) scale(1, 1) translate(-10px, -15px);
	}
	3.33% {
		transform: translate(10px, 15px) scale(1, 0.1) translate(-10px, -15px);
	}
	8.89% {
		transform: translate(10px, 15px) scale(1, 0.6) translate(-10px, -15px);
	}
	24.44% {
		transform: translate(10px, 15px) scale(1, 0.9) translate(-10px, -15px);
	}
	37.78% {
		transform: translate(10px, 15px) scale(1, 0.5) translate(-10px, -15px);
	}
	45.56% {
		transform: translate(10px, 15px) scale(1, 0.8) translate(-10px, -15px);
	}
	64.44% {
		transform: translate(10px, 15px) scale(1, 0.2) translate(-10px, -15px);
	}
	75.56% {
		transform: translate(10px, 15px) scale(1, 0.5) translate(-10px, -15px);
	}
	80% {
		transform: translate(10px, 15px) scale(1, 0.1) translate(-10px, -15px);
	}
	100% {
		transform: translate(10px, 15px) scale(1, 1) translate(-10px, -15px);
	}
}


// Paused Animation
@keyframes paused-anim-1 {
	0% {
		transform: translate(7px, 15px) scale(1, 1) translate(-7px, -15px);
	}
	100% {
		transform: translate(7px, 15px) scale(1, 0.16) translate(-7px, -15px);
	}
}

@keyframes paused-anim-2 {
	0% {
		transform: translate(1px, 15px) scale(1, 1) translate(-1px, -15px);
	}
	100% {
		transform: translate(1px, 15px) scale(1, 0.12) translate(-1px, -15px);
	}
}

@keyframes paused-anim-3 {
	0% {
		transform: translate(4px, 15px) scale(1, 1) translate(-4px, -15px);
	}
	100% {
		transform: translate(4px, 15px) scale(1, 0.24) translate(-4px, -15px);
	}
}

@keyframes paused-anim-4 {
	0% {
		transform: translate(13px, 15px) scale(1, 1) translate(-13px, -15px);
	}
	100% {
		transform: translate(13px, 15px) scale(1, 0.5) translate(-13px, -15px);
	}
}

@keyframes paused-anim-5 {
	0% {
		transform: translate(10px, 15px) scale(1, 1) translate(-10px, -15px);
	}
	100% {
		transform: translate(10px, 15px) scale(1, 0.1) translate(-10px, -15px);
	}
}