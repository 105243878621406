.notification {
	$this: &;

	display: flex;
	@include fixed-top-left();
	justify-content: center;
	width: 100%;
	z-index: 9999;

	&__content {
		background-color: $color-candlelight;
		padding: 0.5em 1em;

		@include breakpoint($breakpoint-mobile) {
			text-align: center;
		}

		@include breakpoint($breakpoint-above-mobile) {
			@include padding-x(5em);
		}

		#{$this}[type="error"] & {
			background-color: $color-milano-red;
			color: $color-white;

			[data-whatintent="mouse"] & .button:hover {
				color: inherit;
			}
		}
	}
}