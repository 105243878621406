.container {
	@include padding-x(30px);

	&--narrow {
		max-width: rem(600);
		margin-left: auto;
		margin-right: auto;
	}

	&--wide {
		max-width: rem(1032);
		margin-left: auto;
		margin-right: auto;
	}

	@include breakpoint($breakpoint-desktop) {
		@include padding-x(0);

		&--wide {
			width: columns(10)
		}
	}
}