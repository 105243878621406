.search-form {
	position: relative;
	display: block;

	&__submit {
		@include size(rem(44));

		position: absolute;
		bottom: 50%;
		right: 0;
		transform: translateY(50%);
		color: $color-sandstone;

		svg {
			@include size(rem(19));

			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
		}
	}

	@include breakpoint($breakpoint-desktop) {
		&__submit {
			@include size(rem(50));

			position: absolute;
			bottom: 50%;
			right: 0;
			transform: translateY(50%);
			color: $color-sandstone;

			svg {
				@include size(100%);
			}

			[data-whatintent="keyboard"] &:focus {
				opacity: 1;
			}
		}

	}
}