.site-nav {
	text-align: center;
	list-style: none;

	&__link {
		display: inline-block;
		margin-left: auto;
		margin-right: auto;
	}

	@include breakpoint($breakpoint-desktop) {
		display: flex;
		align-items: center;

		&__item {
			margin-left: rem(15);
			margin-right: rem(15);
		}

		&__link {
			display: block
		}
	}
}