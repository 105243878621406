// Hide tick icon in popup button.
.optanon-alert-box-wrapper .optanon-button-allow .optanon-alert-box-button-middle button:before {
	display: none;
}

// Set the background colour of the overlay.
#optanon #optanon-popup-bg {
	background-color: rgba($color-mustard, 0.9);
}

// Set the font.
#optanon *,
#optanon *:before,
#optanon *:after,
.optanon-show-settings-popup-wrapper,
.optanon-show-settings-popup-wrapper *,
.optanon-show-settings-popup-wrapper div,
.optanon-show-settings-popup-wrapper span,
.optanon-show-settings-popup-wrapper ul,
.optanon-show-settings-popup-wrapper li,
.optanon-show-settings-popup-wrapper a,
.optanon-show-settings-popup-wrapper p,
#optanon-show-settings-popup ul,
#optanon-show-settings-popup li,
#optanon-show-settings-popup a,
#optanon-show-settings-popup span,
#optanon,
#optanon *,
#optanon div,
#optanon span,
#optanon ul,
#optanon li,
#optanon a,
#optanon p,
.optanon-alert-box-wrapper * {
	font-family: "baskerville-display-pt", serif !important;
}