.home-container {
  background-color: #ffeea0;
  color: #333;
  text-align: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-image: url(./download\ \(3\).png);
}

/* Add this to your Home.css or appropriate CSS file */
@media (max-width: 768px) {
  .site-header__mob-bottom {
    display: none;
  }
  .site-header__mob-bottom.open {
    display: none;
  }
}

.content {
  padding-top: 20%;
}

h1 {
  font-size: 3rem;
  font-family: 'Georgia, serif';
}

p {
  margin-top: 1rem;
  font-size: 1.2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.explore-button {
  margin-top: 2rem;
  padding: 0.5rem 2rem;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
}

.petals-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.petal {
  position: absolute;
  width: 50px;
  height: auto;
  animation: float 10s infinite;
}

/* Random petal positions */
.petal-1 { top: 8%; left: 15%; }
.petal-2 { top: 22%; left: 38%; }
.petal-3 { top: 45%; left: 62%; }
.petal-4 { top: 68%; left: 75%; }
.petal-5 { top: 32%; left: 18%; }
.petal-6 { top: 48%; left: 50%; }
.petal-7 { top: 65%; left: 12%; }
.petal-8 { top: 85%; left: 40%; }
.petal-9 { top: 17%; left: 60%; }
.petal-10 { top: 39%; left: 25%; }
.petal-11 { top: 59%; left: 80%; }
.petal-12 { top: 75%; left: 5%; }

@keyframes float {
  2% { transform: translateY(0); opacity: 1; }
  90% { transform: translateY(calc(100vh - 50px)); opacity: 1; }
  100% { transform: translateY(calc(100vh - 50px)); opacity: 1; }
}

.footer {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
}

.donate-button {
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
}

.footer-links a {
  margin: 0 10px;
  color: #333;
  text-decoration: none;
}

.share-icon {
  font-size: 1.5rem;
}
