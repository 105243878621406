//** Atoms | Nav Toggle

.nav-toggle {
	$toggle-icon-color: $color-sandstone;
	$toggle-text-color: $color-charcoal;
	$toggle-transiton-durection: .4;

	display: flex;
	align-items: center;

	color: $toggle-text-color;

	&__icon {
		position: relative;
		display: block;
	}

	&__icon-dash {
		display: block;
		width: 10px;
		height: 1px;
		background-color: $toggle-icon-color;
		transform-origin: left center;
		transition: transform #{$toggle-transiton-durection * 2}s ease-in-out;

		&:nth-child(2) {
			margin-top: 3px;
			transform: scaleX(.6);
		}

		&:nth-child(3) {
			margin-top: 3px;
			transform: scaleX(.8);
		}
	}

	&__text {
		@include font($font-button);

		position: relative;
		letter-spacing: letter-spacing(3, 12);
		margin-left: 10px;
	}


	&__open,
	&__close {
		transition: opacity #{$toggle-transiton-durection}s ease-in-out;
	}

	&__open {
		opacity: 1;
		transition-delay: #{$toggle-transiton-durection}s;
	}

	&__close {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}

	#{$site-nav-open} & {
		&__open {
			opacity: 0;
			transition-delay: 0s;
		}

		&__close {
			opacity: 1;
			transition-delay: #{$toggle-transiton-durection}s;
		}

		&__icon-dash {
			transform: rotate(45deg) scale(1.1, 1.1);

			&:nth-child(2) {
				transform: scaleX(0);
			}

			&:nth-child(3) {
				transform: rotate(-45deg) scale(1.1, 1.1);
			}
		}
	}
}