.color {
	$color-charcoal: $color-charcoal;
	$color-sandstone: $color-sandstone;

	&-charcoal {
		color: $color-charcoal;
	}

	&-sandstone {
		color: $color-sandstone;
	}
}