.page-border {
	$this: &;
	$border-color: $color-candlelight;

	position: fixed;
	top: 0;
	left: 0;
	display: block;
	height: 100%;
	width: 100%;
	pointer-events: none;
	overflow: hidden;
	z-index: 6;

	span {
		position: fixed;
		display: block;
		background-color: $border-color;
		transition: transform .8s ease-in-out;
		z-index: 99;

		// Top & Bottom
		&:nth-child(-n+2) {
			left: 0;
			width: 100%;
			height: 10px;
		}

		// Left & Right
		&:nth-child(n+3) {
			top: 0;
			height: 100%;
			width: 10px;
		}

		// Top
		&:nth-child(1) {
			top: 0;
			transform: translateY(-100%);
		}

		// Bottom
		&:nth-child(2) {
			position: absolute;
			bottom: 0;
			transform: translateY(100%);
		}

		// Left
		&:nth-child(3) {
			left: 0;
			transform: translateX(-100%);
		}

		// Right
		&:nth-child(4) {
			right: 0;
			transform: translateX(100%);
		}

		#{$tribute-page} &,
		#{$default-page} & {
			&:nth-child(-n+4) {
				transform: translate(0);
			}
		}
	}

	@include breakpoint($breakpoint-desktop) {
		span {
			// Top & Bottom
			&:nth-child(-n+2) {
				height: rem(20);
			}

			// Left & Right
			&:nth-child(n+3) {
				width: rem(20);
			}
		}
	}
}