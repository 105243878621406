//** Atoms | Focus

:focus {
	outline: 2px solid $color-candlelight;

	&.site-nav__link,
	&.button {
		outline: none;
	}
}

html {
	&.js {
		&:not([data-whatintent="keyboard"]) {
			:focus {
				outline: none;
			}
		}
	}
}