.rich-text-component {
	@include padding-y(30px);

	&__container {
		@include breakpoint($breakpoint-desktop) {
			@include margin-x(auto);
			max-width: 60em;
			@include padding-x(60px);
		}
	}

	&__rich-text {
		h1 {
			@extend .h2;
		}

		h2 {
			@extend .h3;
		}

		h3 {
			@extend .h4;
		}
	}
}