$directions: (
	"t": top,
	"r": right,
	"b": bottom,
	"l": left
);

.m {
	@each $name, $direction in $directions {
		&#{$name} {
			@for $size from 1 through 5 {
				&--#{$size} {
					margin-#{$direction}: rem(10 * $size);
				}
			}
		}
	}
}