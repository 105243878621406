.not-supported-modal {
	$this: &;

	&__list {
		margin-top: 30px;
		text-align: center;
	}

	&__link {
		display: inline-block;
		padding: 15px;
		text-align: center;
		width: 110px;

		@include breakpoint($breakpoint-desktop) {
			width: 160px;
		}
	}

	&__image {
		@include size(100%, auto);
	}

	&__text {
		display: inline-block;
		text-align: center;
	}
}