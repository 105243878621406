//** Pages | Tribute Page

#{$tribute-page} {
	$header-background-color: $color-golden-sand;
	$footer-border-color: $color-sandstone-20;

	height: auto;

	&__add-memory-cta {
		text-align: center;
	}

	// .canvas3d {
	// 	opacity: 0;
	// }

	main {
		overflow: hidden;
	}

	.site-footer {
		position: relative;

		padding-bottom: rem(30);

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: rem(30);
			right: rem(30);
			height: rem(1);
			background-color: $footer-border-color;
		}
	}

	&__edit {
		margin-bottom: rem(40);
	}

	@include breakpoint($breakpoint-desktop) {
		.site-header {
			padding-bottom: rem(30);
		}

		.site-footer {
			padding-top: rem(40);
			padding-bottom: rem(60);

			&::before {
				left: rem(60);
				right: rem(60);
			}
		}
	}
}