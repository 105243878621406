.form {
	$this: &;

	@include margin-x(auto);

	position: relative;
	display: block;
	max-width: rem(950);

	&__page {
		&:not(:first-child) {
			margin-top: rem(40);
		}

		.js &:not(#{&}--active) {
			display: none;
		}
	}

	&__validation-summary {
		display: none;
		font-size: rem(14);
		color: $color-milano-red;

		ul {
			padding-left: 1rem;
			margin-top: rem(10);
		}

		&--visible {
			display: block;
			padding-bottom: rem(20);
		}
	}

	&__working-message {
		display: none;
	}

	&--working {
		pointer-events: none;

		@keyframes fadeInOut {
			from {
				opacity: 1;
			}
			to {
				opacity: 0;
			}
		}

		#{$this}__working-message {
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: rem(28);
			letter-spacing: letter-spacing(.7, 28);
			line-height: (38 / 28);
			z-index: 2;
			animation: fadeInOut 1s ease-in-out infinite alternate;
		}

		&::after {
			content: "";

			@include absolute-fill();
			background-color: $color-golden-sand;
			opacity: .7;
			z-index: 1;
		}
	}

	&--edit {
		padding-bottom: rem(60);
	}
}
