.logo-links {
	$this: &;
	$link-border-color: $color-sandstone-50;
	$link-hover-background-color: $color-golden-sand-lighten-12;

	display: flex;
	flex-wrap: wrap;

	list-style: none;

	margin: -5px;

	&--memory {
		justify-content: center;

		#{$this}__item {
			width: 100%;
		}
	}

	&__item {
		width: 50%;
		padding: 5px;
	}

	&__link {
		@include font($font-button);
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding: rem(30) 0;
		border: 1px solid $link-border-color;
		transition: background-color .3s ease-in-out;

		svg {
			margin-bottom: rem(15);
		}

		span {

			display: block;
			width: 100%;
			text-align: center;
			line-height: 1;
		}

		[data-whatintent="mouse"] &:hover,
		.no-js &:hover,
		[data-whatintent="keyboard"] &:focus {
			background-color: $link-hover-background-color;
		}
	}

	@include breakpoint($breakpoint-above-mobile) {
		&--memory {
			#{$this}__item {
				width: 40%;
				max-width: rem(255);

				&:not(:first-child) {
					margin-left: rem(80);
				}
			}
		}

		&__item {
			width: 25%;
		}

		&__link {
			@include padding-y(rem(50));
		}
	}
}