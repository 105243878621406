.tribute-header {
	$this: &;

	position: relative;
	display: flex;
	flex-direction: column;
	margin-top: rem(20);
	margin-bottom: rem(85);
	text-align: center;
	z-index: 1;

	> * {
		@include margin-x(auto);

		width: 100%;
		max-width: 950px;
		order: 2;
	}

	&__date {
		margin-top: 0;
		margin-bottom: rem(30);
		order: 1;
	}

	&__heading {
		margin-bottom: 0;
	}

	&__sub-heading {
		margin-top: rem(15);
		margin-bottom: 0;
	}

	&__text {
		position: relative;
		width: columns(10);
		max-width: 628px;
		margin-top: rem(40);
	}

	&__img {
		position: relative;
		width: columns(10);
		max-width: rem(466);
		margin-top: rem(50);

		img:not([class]) {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&__letter {
		display: none;
	}

	&__petal {
		display: none;
	}

	&--edit {
		margin-bottom: rem(30);

		#{$this}__heading {
			text-align: left;
		}
	}

	@include breakpoint($breakpoint-above-mobile) {
		&__letter {
			position: absolute;
			top: rem(100);
			left: 50%;
			display: block;
			transform: translateX(-50%);
			width: columns(10);
			max-width: rem(1273);
			height: auto;
			opacity: .03;
			z-index: -1;
		}

		&--edit {
			margin-bottom: rem(50);

			#{$this}__heading {
				text-align: center;
			}
		}
	}

	@include breakpoint($breakpoint-desktop) {
		margin-top: rem(40);
		margin-bottom: rem(140);

		&__date {
			margin-bottom: rem(60);
		}

		&__sub-heading {
			margin-top: rem(40);
		}

		&__text {
			margin-top: rem(80);
		}

		&__img {
			margin-top: rem(100);
		}

		&__letter {
			top: rem(200);
		}

		&__petal {
			position: absolute;
			display: block;
			z-index: -1;

			&--header {
				top: rem(70);
				right: rem(-50);
				width: rem(180);
			}

			&--summary {
				top: rem(41);
				right: rem(-224);
				width: rem(166);
			}

			&--side {
				top: 60%;
				left: columns(1.2);
				width: rem(360);
			}
		}

		&--edit {
			margin-bottom: rem(80);
		}
	}

	@include breakpoint($breakpoint-retina) {
		> * {
			max-width: columns(4)
		}

		&__heading {
			max-width: columns(6)
		}

		&__img {
			max-width: rem(466);
		}

		&__letter {
			max-width: rem(1273);
		}

		&__petal {
			&--summary {
				right: rem(-400);
			}
		}
	}
}